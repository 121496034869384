import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, collection, doc, addDoc, updateDoc, deleteDoc, query, where, orderBy, getDocs } from "firebase/firestore";

//=========================================================
//  Configuration Firebase
//=========================================================

const firebaseConfig = {
    //    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    //    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    //    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    //    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    //    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    //    appId: process.env.REACT_APP_FIREBASE_APP_ID

    apiKey: "AIzaSyBNCI_clMc6NNnoYYhhPjPQS_IjkDsJuFs",
    authDomain: "azti-c9045.firebaseapp.com",
    projectId: "azti-c9045",
    storageBucket: "azti-c9045.appspot.com",
    messagingSenderId: "435150739621",
    appId: "1:435150739621:web:045376df4068ad6ae7554c"
}

class Firebase {

//=========================================================
//  Data
//=========================================================

    state = {
        userId: '',
        userEmail: '',
        isUserAdmin: false,
        isConnected: false
    }

    // initialisation de firebase et de la base de données
    constructor() {
        this.firebaseApp = initializeApp(firebaseConfig);
        this.db = getFirestore(this.firebaseApp);
        this.auth = getAuth(this.firebaseApp);

    }

//========================================================    
// Méthodes pour accéder à Firestore
//========================================================    

    // inscription
    async signupUser(email, password) {
        let authUser = '';
        try {
            await createUserWithEmailAndPassword(this.auth, email, password)
                .then((userCredential) => {
                    // Signed in 
                    authUser = userCredential.user;
                })
                .catch((error) => {
                    authUser = 'Erreur: utilisateur déjà inscrit';
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log('Erreur ', errorCode, ' ', errorMessage);
                });
        } catch (error) {
            authUser = 'Erreur: utilisateur déjà inscrit';
            console.error("Error: ", error.code);
        }
        return authUser;
    }

    // Connexion
    async loginUser(email, password) {
        let authUser = '';
        try {
            await signInWithEmailAndPassword(this.auth, email, password)
                .then((userCredential) => {
                    // Signed in 
                    authUser = userCredential.user;
                    this.state.userId = authUser.uid;
                    this.state.userEmail = email;
                    this.state.isConnected = true;
                })
                .catch((error) => {
                    authUser = 'Erreur: utilisateur inconnu ou mot de passe invalide';
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log('Erreur ', errorCode, errorMessage);
                });
        } catch (error) {
            authUser = 'Erreur: utilisateur inconnu ou mot de passe invalide';
            console.error("Error: ", error);
        }

        return authUser;
    }

    // Déconnexion
    signoutUser = () => {
        this.auth.signOut();
        this.state.userId = '';
        this.state.userEmail = '';
        this.state.isUserAdmin = false;
        this.state.isConnected = false;
    }

    // Récupérer le mot de passe
    async passwordReset(email) {
        try {
            sendPasswordResetEmail(this.auth, email)
                .then(() => {
                    console.log(`Consultez votre email ${email} pour changer le mot de passe`);
                })
        } catch (error) {
        }
    }

    // isAdmin
    isAdmin = () => {
        return this.state.isUserAdmin;
    }

    // Vérification connexion
    checkConnected = () => {
        return this.state.isConnected;
    }

    // Get email
    getUserEmail = () => {
        return this.state.userEmail;
    }

    // créer un user    
    async createUser(firstname, name, company, phone, email, typeUser, adresse1, adresse2, codepostal, ville) {

        let size = await this.checkUser(firstname, name);
        if (size === 0) {
            console.log("ok user créé");
            await this.addUser(firstname, name, company, phone, email, typeUser, adresse1, adresse2, codepostal, ville);
        }
        else {
            console.log("user déjà existant");
        }

    }

    // créer un user    
    async addUser(firstname, name, company, phone, email, typeUser, adresse1, adresse2, codepostal, ville) {
        try {
            console.log(email);
            const docRef = await addDoc(collection(this.db, "users"), {
                prenom: firstname,
                nom: name,
                societe: company,
                adresse1: adresse1,
                adresse2: adresse2,
                codepostal: codepostal,
                ville: ville,
                tel: phone,
                email: email,
                typeUser: typeUser
            });
            console.log("Document written with ID: ", docRef.id);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    }

    // modifier un user    
    async updateUser(userId, firstname, name, company, phone, email, typeUser, adresse1, adresse2, codepostal, ville) {
        try {
            const docRef = doc(this.db, 'users', userId);
            await updateDoc(docRef, {
                prenom: firstname,
                nom: name,
                societe: company,
                adresse1: adresse1,
                adresse2: adresse2,
                codepostal: codepostal,
                ville: ville,
                tel: phone,
                email: email,
                typeUser: typeUser
            });
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    // vérifier si un user existe par nom et prenom    
    async checkUser(prenom, nom) {
        const q = query(collection(this.db, "users"), where("prenom", "==", prenom), where("nom", "==", nom));

        const querySnapshot = await getDocs(q);
        return (querySnapshot.size);
    }

    // vérifier si un user existe par email et renvoyer le type   
    async checkEmail(email) {
        const q = query(collection(this.db, "users"), where("email", "==", email));

        const querySnapshot = await getDocs(q);
        let typeUser = 'user';
        if (querySnapshot.size === 1) {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                typeUser = doc.data().typeUser;
                typeUser === "admin" ? this.state.isUserAdmin = true : this.state.isUserAdmin = false;
            });
        }
        return (typeUser);
    }

    // vérifier si un user existe par email et renvoyer l'objet  
    async checkUserByEmail(email) {
        const q = query(collection(this.db, "users"), where("email", "==", email));
        let userData = '';
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size === 1) {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                userData = doc.data();
            });
        }
        return(userData);
    }

    // vérifier si un user existe par prenom et nom et retourner id 
    async checkUserByPrenomNom(prenom, nom) {
        const q = query(collection(this.db, "users"), where("prenom", "==", prenom), where("nom", "==", nom));
        let userId = '-1';

        const querySnapshot = await getDocs(q);
        if (querySnapshot.size === 1) {
            querySnapshot.forEach((doc) => {
                userId = doc.id;
            });
        }
        return (userId);
    }

    // Trouver tous les users    
    async getUsers() {
        try {
            const q = query(collection(this.db, "users"), orderBy("nom", "asc"));

            const querySnapshot = await getDocs(q);
            return (querySnapshot);
        } catch (error) { }
    }

    // delete user
    async deleteUser(userId) {
        try {
            const docRef = doc(this.db, 'users', userId);
            await deleteDoc(docRef);
        } catch (error) {
            console.error("Error deleting document: ", error);
        }
    }

    // créer un message    
    async addMessage(email, univDate, currentDate, objet, message) {
        try {
            const docRef = await addDoc(collection(this.db, "messages"), {
                email: email,
                univDate: univDate,
                currentDate: currentDate,
                objet: objet,
                message: message
            });
            console.log("Document written with ID: ", docRef.id);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    }

    // Lire tous les messages d'un même email    
    async getMessagesByEmail(email) {
        const q = query(collection(this.db, "messages"), where("email", "==", email), orderBy("univDate", "desc"));

        const querySnapshot = await getDocs(q);

        return (querySnapshot);

    }

}

export default Firebase;
