import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import { FirebaseContext } from './Firebase';

const Navigation = () => {

//=========================================================
//  Data
//=========================================================

    const firebase = useContext(FirebaseContext);

//=========================================================
//  Fonctions
//=========================================================      

    const isConnected = () => {
        return firebase.checkConnected();
    }

    const isAdmin = () => {
        return firebase.isAdmin();
    }

    const tailleEcran = () => {
        return (window.innerWidth > 576 ? true : false )
    }
      
//=========================================================

    return (
        <div className='navigation'>
            {tailleEcran() &&
            <div>
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-3">
                        <img className="imageLogo" src="./img/AZTILogo.png" alt="logo" />
                    </div>
                    <div className="col-2"></div>
                    <div className="col-4 textOption">
                        <NavLink exact to="/" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                            Accueil
                        </NavLink>
                        <NavLink exact to="contact" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                            Contact
                        </NavLink>    
                        {isAdmin() && isConnected() &&             
                            (<NavLink exact to="clients" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                                Utilisateurs
                            </NavLink>)}
                        {!isAdmin() && isConnected() &&              
                            (<NavLink exact to="messages" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                                Messages
                            </NavLink>)}
                        {!isConnected() &&               
                            (<NavLink exact to="login" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                                Connexion
                            </NavLink>)}
                        {isConnected() &&
                            (<NavLink exact to="logout" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                                Déconnexion
                            </NavLink>)}
                    </div>
                </div>
                <div className="pieddepage">
                    <div className="row espace">
                        <div className="col-1"></div>
                        <div className="col-6">
                            <NavLink className="navColor" exact to="/mentions" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                                Mentions&nbsp;légales
                            </NavLink>
                            <NavLink className="navColor" exact to="/cgu" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                                CGU
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            }
            {!tailleEcran() &&
            <div>
                <div className="row">
                    <div className="col-2">
                        <img className="imageLogo2" src="./img/AZTILogo.png" alt="logo" />
                    </div>
                    <div className="col-1"></div>
                    <div className="col-3 textOption">
                        <NavLink exact to="/" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                            Accueil
                        </NavLink>
                        <NavLink exact to="contact" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                            Contact
                        </NavLink>    
                        {isAdmin() && isConnected() &&             
                            (<NavLink exact to="clients" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                                Utilisateurs
                            </NavLink>)}
                        {!isAdmin() && isConnected() &&              
                            (<NavLink exact to="messages" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                                Messages
                            </NavLink>)}
                        {!isConnected() &&               
                            (<NavLink exact to="login" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                                Connexion
                            </NavLink>)}
                        {isConnected() &&
                            (<NavLink exact to="logout" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                                Déconnexion
                            </NavLink>)}
                    </div>
                </div>
                <div className="pieddepage2">
                    <div className="row espace">
                        <div className="col-1"></div>
                        <div className="col-6">
                            <NavLink className="navColor" exact to="/mentions" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                                Mentions&nbsp;légales
                            </NavLink>
                            <NavLink className="navColor" exact to="/cgu" activeStyle={{ color: "rgb(191, 113, 60)" }}>
                                CGU
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}


export default Navigation;